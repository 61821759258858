<template>
  <div class="categories-item" v-if="!(($route.name === 'Calendar View') && category.date)">
    <TheCategoriesTitle
      :title="category.title"
      :number="selectedLength"
      :category="category.guid"
      :context="context"
    />

    <TheCategoriesSearch
      :name="category.guid"
      :context="context"
      v-if="category.searchbar"
      @update="handleSearchUpdate"
    />

    <TheCategoriesList
      :category="category"
      :category-index="categoryIndex"
      :context="context"
      :query="searchQuery"
      v-if="category.items.length"
      @update="handleUpdate"
    />

    <TheCategoriesDate
      :context="context"
      @update="handleUpdate"
      v-else-if="!category.items.length && category.date"
    />
  </div>
</template>

<script>
import TheCategoriesTitle from './TheCategoriesTitle'
import TheCategoriesList from './TheCategoriesList'
import TheCategoriesDate from './TheCategoriesDate'
import TheCategoriesSearch from './TheCategoriesSearch'

export default {
  name: 'TheCategoriesItem',
  props: {
    category: {
      type: Object,
      required: true
    },
    categoryIndex: {
      type: Number,
      required: true
    },
    context: {
      type: String,
      required: true
    }
  },
  components: {
    TheCategoriesTitle,
    TheCategoriesSearch,
    TheCategoriesDate,
    TheCategoriesList
  },
  data () {
    return {
      selectedLength: 0,
      searchQuery: ''
    }
  },
  methods: {
    handleUpdate (length) {
      this.selectedLength = length
    },
    handleSearchUpdate (searchString) {
      this.searchQuery = searchString
    },
    reset () {
      this.$store.dispatch(`${this.context}/updateAllItemsActive`, { index: this.index, active: false })
    }
  }
}
</script>

<style lang="scss">
  .categories-item{
    @include media("small"){
      width: calc(100% - #{layout("spacing")});
    }
    @include media("medium"){
      width: calc(50% - #{layout("spacing")});
    }
    @include media("large-only"){
      width: calc(33.3333% - #{layout("spacing")});
    }
    @include media("xlarge"){
      width: calc(25% - #{layout("spacing")});
      max-width: calc(50% - #{layout("spacing")});
    }

    margin-right: layout("spacing")/2;
    margin-left: layout("spacing")/2;
    padding-bottom: layout("spacing");
    // Parents

    .filters--contact &{
      @include media("small-medium"){
        width: 100%;
      }
      @include media("large-only"){
        width: calc((100% - #{layout("spacing")*2}) / 2) !important;
      }
      @include media("xlarge"){
        width: calc((100% - #{layout("spacing")*4}) / 4);
      }
    }
  }
</style>
