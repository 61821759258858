<template>
  <div class="categories-date">
    <div class="categories-date__datepicker-wrapper">
      <datepicker class="categories-date__datepicker" placeholder="From" v-model="start" />
      <div class="categories-date__icon"></div>
    </div>
    <div class="categories-date__datepicker-wrapper">
      <datepicker class="categories-date__datepicker" placeholder="To" v-model="end" />
      <div class="categories-date__icon"></div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
export default {
  name: 'TheCategoriesDate',
  props: {
    context: {
      type: String,
      required: false,
      default: 'filters'
    }
  },
  computed: {
    startDate () {
      return this.$store.getters[`${this.context}/getStartDate`] || ''
    },
    endDate () {
      return this.$store.getters[`${this.context}/getEndDate`] || ''
    },
    start: {
      get () {
        return this.startDate
      },
      set (value) {
        this.$store.dispatch(`${this.context}/updateStartDate`, value)
      }
    },
    end: {
      get () {
        return this.endDate
      },
      set (value) {
        this.$store.dispatch(`${this.context}/updateEndDate`, value)
      }
    }
  },
  components: {
    Datepicker
  }
}
</script>

<style lang="scss">
  .categories-date{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: - layout("spacing")/2;
    margin-right: - layout("spacing")/2;
    padding-top: layout("spacing");

    // DatePicker Wrapper

    &__datepicker-wrapper{
      flex: 1 1 auto;
      margin-left: layout("spacing")/2;
      margin-right: layout("spacing")/2;
      margin-bottom: layout("spacing");
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

    }

    // DatePicker

    &__datepicker{
      flex: 0 1 auto;
      width: 100%;

      label{
        margin-bottom: 0;
      }

      input{
        @include border-left-radius(layout("border-radius"));
        width: 100%;
        color: white;
        margin-bottom: 0;
        font-size: 1.5rem;
        border: 1px solid white;
        border-right: 0;
        background-color: transparent;
        font-weight: font-weights("medium");
        padding: layout("spacing")*0.4;

        &:hover,
        &:focus{
          background-color: transparent;
          box-shadow: none;
        }
      }

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: white;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: white;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: white;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: white;
      }

      .vdp-datepicker__calendar{
        min-width: 25rem;
      }
    }

    // Icon

    &__icon{
      @include icon(icons("events"));
      @include border-right-radius(layout("border-radius"));
      flex: 0 0 auto;
      width: 3.5rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: white;
    }
  }
</style>
