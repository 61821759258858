<template>
  <div class="checkbox" :class="customClasses">
    <label class="checkbox__label" :for="id">
      <span class="checkbox__square"></span>
      <input class="checkbox__input"
        :id="id"
        :name="name || guid"
        :value="value"
        :type="'checkbox'"
        :checked="active"
        @change="handleChange" />
      <ElementBadge class="checkbox__badge" :text="label" :color="color" :colorHex="colorHex" />
    </label>
  </div>
</template>

<script>
import ElementBadge from '@elements/ElementBadge'

export default {
  name: 'BaseCheckbox',
  props: {
    guid: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    hollow: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: () => {}
    },
    colorHex: {
      type: String,
      required: false,
      default: ''
    },
    active: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  components: {
    ElementBadge
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.active
    },
    // name getter
    name () {
      return this.name
    }
  },
  data () {
    return {
      model: this.active
    }
  },
  computed: {
    customClasses () {
      return [
        { 'is-selected': this.active, 'is-hollow': this.hollow }
      ]
    },
    id () {
      return `checkbox-${this.guid}`
    }
  },
  methods: {
    handleChange () {
      this.$emit('change', {
        guid: this.guid,
        label: this.label,
        value: this.value,
        active: !this.active
      })

      this.$emit('input', {
        guid: this.guid,
        label: this.label,
        value: this.value,
        active: !this.active
      })
    }
  }
}
</script>

<style lang="scss">
  .checkbox{

    // Label

    &__label{
      position: relative;
      font-weight: font-weights("medium");
      font-size: 1.4rem;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      .checkbox__badge {
        flex: 0 1 auto;
      }

      .checkbox__square{
        flex: 0 0 auto;
      }
    }

    // Square

    &__square{
      @include size(1.6rem);
      @include icon(icons("check"));

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      background-color: white;
      border: 1px solid shade(colors("foreground"), 20%);

      &::before{
        @include add-transition;
        color: colors("primary");
        font-size: 0.9rem;
        opacity: 0;
      }

      .is-selected &{
        &::before{
          opacity: 1;
        }
      }

      .is-hollow &{
        border: 1px solid white;
        background-color: transparent;

        &::before{
          color: white;
        }
      }
    }

    // Input

    &__input{
      @include hide-visually;
      flex: 1 1 auto;
      order: 1;
    }

    // Badge

    &__badge{
      order: 2;
      flex: 0 1 auto;
      margin-left: layout("spacing")/2;

      .badge__text{
        @include add-hover-underline(left, white, 1px);
      }
    }
  }
</style>
