
<template>
  <div :class="mainClasses">
    <TheCategoriesItem
      ref="categories"
      :context="context"
      :category="category"
      :category-index="index"
      :key="category.guid"
      v-for="(category, index) in categories"
    />
  </div>
</template>

<script>
import TheCategoriesItem from './TheCategoriesItem'

export default {
  name: 'TheCategories',
  props: {
    categories: {
      type: Array,
      required: true
    },
    context: {
      type: String,
      required: true
    }
  },
  components: {
    TheCategoriesItem
  },
  computed: {
    mainClasses () {
      return [
        'categories',
        `categories--${this.evenOdd}`
      ]
    },
    evenOdd () {
      return this.categories.length % 2 === 0 ? 'even' : 'odd'
    }
  }
}
</script>

<style lang="scss">
  .categories{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;

    .filters &{
      @include media("large"){
        margin-left: - layout("spacing");
        margin-right: - layout("spacing");
      }
    }

    > *{
      flex: 0 1 auto;
    }
  }
</style>
