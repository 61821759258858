<template>
  <div class="categories-title">
    <ElementIconText :text="title" :icon="icon" />
    <ElementTag :text="String(number)" color="yellow" v-if="showTag" />
  </div>
</template>

<script>
import ElementTag from '@elements/ElementTag'
import ElementIconText from '@elements/ElementIconText'

export default {
  name: 'TheCategoriesTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    context: {
      type: String,
      required: true
    }
  },
  components: {
    ElementTag,
    ElementIconText
  },
  computed: {
    icons () {
      return this.$store.getters[`${this.context}/getIcons`]
    },
    icon () {
      return this.icons[this.category]
    },
    showTag () {
      return this.category !== 'date'
    }
  }
}
</script>

<style lang="scss">
  .categories-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    padding-bottom: layout("spacing")/2;
    border-bottom: 1px solid tint(colors("font"), 80%);

    .filters &{
      border-bottom: 1px solid white;
    }

    .icon-text{
      flex: 0 1 auto;
      color: colors("font");
      margin-right: layout("spacing")/2;
      font-weight: font-weights("medium");

      .icon::before{
        color: tint(colors("font"), 50%);
      }

      .filters &{
        color: white;
        font-weight: font-weights("bold");
        .icon::before{
          color: white;
        }
      }
    }

    .tag{
      flex: 0 0 auto;
    }
  }
</style>
