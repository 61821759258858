<template>
  <div class="categories-search">
    <label :for="id">
      <input :id="id" :name="name" type="search" :placeholder="$t('message.filters.search.placeholder')" v-model="search" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'TheCategoriesSearch',
  props: {
    name: {
      type: String,
      required: true
    },
    context: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    id () {
      return `input-search-${name}`
    }
  },
  watch: {
    search () {
      this.$emit('update', this.search)
    }
  }
}
</script>

<style lang="scss">
  .categories-search{
    padding-top: layout("spacing");

    label{
      margin-bottom: 0;
    }

    input{
      width: 100%;
      color: colors("font");
      margin-bottom: 0;
      font-size: 1.5rem;
      border-radius: 4px;
      border: 1px solid colors("font");
      background-color: transparent;
      font-weight: font-weights("medium");
      padding: layout("spacing")*0.4;

      &:hover,
      &:focus{
        background-color: transparent;
      }

      .filters &{
        color: white;
        border: 1px solid white;
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: colors("font");
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: colors("font");
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: colors("font");
    }
    :-moz-placeholder { /* Firefox 18- */
      color: colors("font");
    }

    .filters &{
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: white;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: white;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: white;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: white;
      }
    }
  }
</style>
