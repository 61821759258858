<template>
  <div class="categories-list">
    <BaseCheckbox
      :hollow="true"
      :guid="`${category.guid}-all`"
      :label="$t('message.filters.labels.all')"
      :value="'all'"
      :active="allModel"
      v-if="category.all && query === ''"
      @change="handleAllchange"
    />

    <ul class="categories-list__list" v-if="items.length">
      <li
        v-for="item in items"
        :key="item.guid"
        :class="[
          'categories-list__item',
          { 'has-parent': item.parent !== '' }
        ]">

        <BaseCheckbox
          :hollow="true"
          :guid="item.guid"
          :label="item.label"
          :value="item.value"
          :active="item.active"
          :name="item.name || null"
          :colorHex="item.color || ''"
          v-show="item.show"
          @change="handleChange"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import BaseCheckbox from '@base/BaseCheckbox'

export default {
  name: 'TheCategoriesList',
  props: {
    category: {
      type: Object,
      required: true
    },
    categoryIndex: {
      type: Number,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    context: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      allLength: 0,
      allModel: false,
      filteredItems: {},
      urlFilters: []
    }
  },
  computed: {
    selected () {
      return this.$store.getters[`${this.context}/getSelected`]
    },
    items () {
      return this.category.items
    },
    calculatedLength () {
      let length = 0
      this.items.forEach(item => {
        if (item.show) length += 1
      })
      return length
    }
  },
  watch: {
    items: {
      handler (val, oldVal) {
        this.$store.dispatch(`${this.context}/updateSelected`)
        this.allModel = this.selected[this.categoryIndex].items.length === this.calculatedLength && this.calculatedLength !== 0
      },
      deep: true
    },
    selected () {
      this.$emit('update', this.selected[this.categoryIndex].items.length)
    },
    query () {
      this.$store.dispatch(`${this.context}/updateAllItemsShow`, { categoryIndex: this.categoryIndex, query: this.query })
    }
  },
  components: {
    BaseCheckbox
  },
  mounted () {
    this.urlFilters = this.$route.query.filters ? this.$route.query.filters.split(',') : []
    if (this.urlFilters.length > 0) this.setActiveUrlFilters()
    else this.$store.dispatch(`${this.context}/updateSelected`)
  },
  methods: {
    handleAllchange ({ active }) {
      const condition = (active || (!active && (this.calculatedLength === this.selected[this.categoryIndex].items.length || !this.selected[this.categoryIndex].items.length)))
      if (condition) {
        this.$store.dispatch(`${this.context}/updateAllItemsActive`, {
          categoryIndex: this.categoryIndex,
          active
        })
      }
    },
    handleChange ({ guid, active }) {
      this.$store.dispatch(`${this.context}/updateItemActive`, {
        categoryIndex: this.categoryIndex,
        guid,
        active
      })
    },
    setActiveUrlFilters () {
      this.urlFilters.forEach(filter => {
        this.items.forEach(item => {
          if (item.guid === filter) {
            this.handleChange({ guid: filter, active: true })
          }
        })
      })
      this.$store.dispatch(`${this.context}/updateSelected`)
    }
  }
}
</script>

<style lang="scss">
  .categories-list{
    padding-top: layout("spacing");
    max-height: 26rem;
    overflow-y: auto;

    > .checkbox{
      margin-bottom: layout("spacing");
    }

    // List

    &__list{
      @include no-type-list;

      li{
        &.has-parent{
          padding-left: layout("spacing")*1.3;
        }
      }
    }

    // Checkbox
    .checkbox{
      color: colors("font");

      label{
        font-weight: font-weights("normal");
      }

      &__square{
        border: 1px solid tint(colors("font"), 50%);
        background-color: white;

        &::before{
          color: colors("primary");
        }

        .filters &{
          border: 1px solid white;
          background-color: transparent;

          &::before{
            color: white;
          }
        }
      }

      .filters &{
        color: white;

        label{
          font-weight: font-weights("bold");
        }
      }
    }
  }
</style>
